import type { Options, ScaleImageOptions } from "./types";

/**
 * Returns cropped contentful image with parameters
 * @param {string} operation - Operation Name
 * @param {} params - Object of params
 * @returns {string} Bynder transform param string
 */
const generateTransformParamsString = (operation: string, params = {}) => {
  const paramsString = Object.keys(params)
    .map((key) => `${key}:${params[key]}`)
    .join(",");

  return `io=transform:${operation},${paramsString}`;
};

/**
 * Returns cropped contentful image with parameters
 * @param {string} url - Image Url
 * @returns {baseUrl: string, urlParams: string } Object with baserUrl and UrlParams properties
 */
const parseImageUrl = (url: string) => {
  const splitUrl = url.split("?");
  return {
    baseUrl: splitUrl[0],
    urlParams: splitUrl[1],
  };
};

/**
 * Returns cropped contentful image with parameters
 * @param {string} urlParams - Url Params String
 * @returns {string | null} Focus Param Values or null
 */
const getFocusPointParam = (urlParams: string) => {
  if (urlParams) {
    const parsedParams = new URLSearchParams(urlParams);
    return parsedParams.get("focuspoint");
  }
  return null;
};
/**
 * Returns cropped contentful image with parameters
 * @param {string} url - Image url
 * @param {number} width - Desired width image
 * @param {number} height - Desired height image
 * @param {object} options - Object with Contentful specific query params
 * @returns {string} Bynder cropped image url
 */
export const croppedImageUrl = (
  url: string,
  width: number,
  height: number,
  options?: Options
): string => {
  const gravity =
    options?.f && ["face", "faces"].includes(options?.f) ? "center" : options?.f || "center";

  const transformParams = {
    gravity,
    width,
    height,
  };

  const imageFormat = options?.fm || "webp";
  const imageQuality = options?.q || 70;
  const { baseUrl, urlParams } = parseImageUrl(url);
  const focusPointValues = getFocusPointParam(urlParams);

  return `${baseUrl}?format=${imageFormat}&quality=${imageQuality}&${generateTransformParamsString(
    "fill",
    transformParams
  )}${focusPointValues ? "&focuspoint=" + focusPointValues : ""}`;
};

/**
 * Returns scaled contentful image with parameters
 * @param {string} url - Image url
 * @param {number} width - Desired width of scaled image
 * @param {object} options - Object with Contentful specific query params
 * @returns {string} Bynder scaled image url
 */
export const scaledImageUrl = (url: string, width: number, options?: ScaleImageOptions): string => {
  const imageFormat = options?.fm || "webp";
  const imageQuality = options?.q || 70;
  const { baseUrl, urlParams } = parseImageUrl(url);
  const focusPointValues = getFocusPointParam(urlParams);

  return `${baseUrl}?format=${imageFormat}&quality=${imageQuality}&${generateTransformParamsString(
    "scale",
    { width }
  )}${focusPointValues ? "&focuspoint=" + focusPointValues : ""}`;
};
