import { Options, ScaleImageOptions, ImageType } from "./types";
import {
  croppedImageUrl as contentfulCroppedImageUrl,
  scaledImageUrl as contentfulScaledImageUrl,
  supportsWebP,
} from "./contentful-image-resize";
import {
  croppedImageUrl as bynderCroppedImageUrl,
  scaledImageUrl as bynderScaledImageUrl,
} from "./bynder-image-resize";

const isBynderImageUrl = (url: string): boolean => url.includes("/images/transform");

const croppedImageUrl = (url: string, width: number, height: number, options?: Options): string =>
  isBynderImageUrl(url)
    ? bynderCroppedImageUrl(url, width, height, options)
    : contentfulCroppedImageUrl(url, width, height, options);

const scaledImageUrl = (url: string, width: number, options?: ScaleImageOptions): string =>
  isBynderImageUrl(url)
    ? bynderScaledImageUrl(url, width, options)
    : contentfulScaledImageUrl(url, width, options);

export { supportsWebP, croppedImageUrl, scaledImageUrl };
export type { ImageType };
