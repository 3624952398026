import type { Options, ScaleImageOptions, BaseParams } from "./types";

const generateQueryParams = (base: BaseParams, options: Options = {}): string => {
  const filteredOptions = Object.keys(options)
    .filter((key) => !!options[key])
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: options[curr],
      }),
      {}
    );

  const params = { ...base, ...filteredOptions };

  const queryParams = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

  return queryParams;
};

/**
 * Check if browser supports webP to help development for safari for macOS under v.11
 * @returns {boolean} boolean depending if browser supports webP or not
 */
const supportsWebP = (): boolean => {
  const elem = document.createElement("canvas");
  if (elem.getContext && elem.getContext("2d")) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }

  return false;
};

/**
 * Returns cropped contentful image with parameters
 * @param {string} id - Image url
 * @param {number} width - Desired width image
 * @param {number} height - Desired height image
 * @param {object} options - Object with Contentful specific query params
 * @returns {string} Contentful cropped image url
 */
const croppedImageUrl = (url: string, width: number, height: number, options?: Options): string => {
  const base = {
    f: "center",
    fit: "fill",
    w: width,
    h: height,
    fm: "webp",
    q: 70,
  };

  const queryParams = generateQueryParams(base, options);

  return `${url}?${queryParams}`;
};

/**
 * Returns scaled contentful image with parameters
 * @param {string} url - Image url
 * @param {number} width - Desired width of scaled image
 * @param {object} options - Object with Contentful specific query params
 * @returns {string} Contentful scaled image url
 */
const scaledImageUrl = (url: string, width: number, options?: ScaleImageOptions): string => {
  const base = {
    fit: "scale",
    w: width,
    fm: "webp",
    q: 70,
  };
  return `${url}?${generateQueryParams(base, options)}`;
};

export { croppedImageUrl, scaledImageUrl, supportsWebP };
